export default [
  {
    title: 'Conversions',
    icon: 'AwardIcon',
    children: [
      {
        title: 'View New',
        route: 'browse-conversions-new',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
      {
        title: 'View Verified',
        route: 'browse-conversions-verified',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
      {
        title: 'View Won',
        route: 'browse-conversions-won',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
      {
        title: 'View Rejected',
        route: 'browse-conversions-rejected',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
      {
        title: 'View Spam',
        route: 'browse-conversions-spam',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
      {
        title: 'All Conversions',
        route: 'browse-conversions',
        // acl
        action: 'read',
        resource: 'Conversion',
      },
    ],
  },
]
