export default [
  {
    title: 'Account Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'My Profile',
        route: 'settings-profile',
        // acl
        action: 'read',
        resource: 'Account',
      },
      {
        title: 'Billing & Plans',
        route: 'settings-billing',
        // acl
        action: 'read',
        resource: 'Account',
      },
      {
        title: 'Invite Users',
        route: 'settings-users',
        // acl
        action: 'read',
        resource: 'Account',
      },
    ],
  },
]
