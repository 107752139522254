export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    // acl
    action: 'read',
    resource: 'Dashboard',
  },
]
