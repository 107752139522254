/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

/* import apps from './apps-and-pages' */
import dashboard from './dashboard'
import onboarding from './onboarding'
import conversions from './conversions'
// import phone from './phone'
import settings from './settings'

// Export array of sections
export default {
  all: [
    // ...apps,
    ...dashboard,
    ...onboarding,
    ...conversions,
    // ...phone,
    ...settings,
  ],
  onboarding: [
    ...onboarding,
  ],
}
