export default [
  {
    title: 'Get Started',
    icon: 'HelpCircleIcon',
    route: 'onboarding',
    // acl
    action: 'read',
    resource: 'Onboarding',
  },
]
